import { FunctionComponent, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useHeaderUpdater } from "@components/HeaderContextProvider";
import { DynamicActivity, IConfiguration } from "@domain/types";
import { assertIsDefined } from "@domain/utils";
import useCreateDynamicActivityMutation from "@hooks/useCreateDynamicActivityMutation";
import useDynamicActivityConfiguration from "@hooks/useDynamicActivityConfiguration";
import type { To } from "@remix-run/router";
import ImageHeader from "src/components/Trainer/ImageHeader";

import { useApplicationSize } from "@anwb/poncho/providers/providers-application";

import CenteredSpinner from "@topgun/shared-front-end/src/components/CenteredSpinner";

type TrainerUrlProps = {
  configurationId: string;
};

function getNavigateBackUrl(trainer: IConfiguration): To {
  if (trainer.type === "CHAPTER_TEST") {
    return `/chapters/${trainer.belongsTo}`;
  }
  if (trainer.type === "PRACTICE_EXAM") {
    return `/dashboard`;
  }
  return "/trainers";
}

const Trainer: FunctionComponent = () => {
  const { configurationId } = useParams<TrainerUrlProps>();
  assertIsDefined(configurationId, "Unknown configuration ID");

  const navigate = useNavigate();
  const applicationSize = useApplicationSize();
  const {
    isLoading,
    error,
    dynamicActivityConfiguration: trainer,
  } = useDynamicActivityConfiguration(configurationId);
  const {
    isLoading: isCreating,
    error: errorWhileCreating,
    createDynamicActivity,
  } = useCreateDynamicActivityMutation(configurationId);
  const { setShowHeaderMenu, setNavigateBack } = useHeaderUpdater();

  useEffect(() => {
    if (trainer) {
      setNavigateBack({
        to: getNavigateBackUrl(trainer),
      });
    }
  }, [trainer, setNavigateBack]);

  useEffect(() => {
    setShowHeaderMenu(applicationSize !== "small");
  }, [setShowHeaderMenu, applicationSize]);

  if (error) {
    const newError = new Error(`Trainer '${configurationId}' not found`);
    newError.name = "notFound";
    throw newError;
  }

  if (errorWhileCreating) {
    const newError = new Error(`Trainer '${configurationId}' couldn't be started`);
    newError.name = "genericError";
    throw newError;
  }

  const startPlayer = () => {
    createDynamicActivity(undefined, {
      onSuccess: (dynamicActivity: DynamicActivity) => {
        if ((dynamicActivity.segments ?? []).length > 0 && dynamicActivity.segments[0].content) {
          const firstSegment = dynamicActivity?.segments[0].id;
          navigate(`/trainers/${configurationId}/${dynamicActivity.id}/status/${firstSegment}`);
        } else {
          const firstPage = dynamicActivity.pages[0].id;
          navigate(`/trainers/${configurationId}/${dynamicActivity.id}/page/${firstPage}`);
        }
      },
    });
  };

  if (!isLoading && !isCreating && trainer) {
    return (
      <ImageHeader
        totalAmount={trainer.totalAmount}
        title={trainer.title}
        introductionText={trainer.introductionText}
        onButtonClick={startPlayer}
        backgroundImage={trainer.image}
        video={trainer.video}
      />
    );
  }
  return <CenteredSpinner size="large" />;
};

export default Trainer;
