import React, { FunctionComponent } from "react";
import { createPortal } from "react-dom";

import { SpinnerWrapper } from "@components/Activity/styles/activity.styled";
import {
  MobileBackButton,
  MobileNavigationButtonWrapper,
  MobileNextButton,
  NavigationButtonWrapper,
  PlayerContainer,
  PlayerWrapper as PlayerWrapperStyledComponent,
} from "@components/Player/styles/Player.styled";
import { NavigationButton } from "@components/buttons";
import usePlayLearningObject from "@hooks/usePlayLearningObject";
import PlayerWrapper from "src/components/Player/PlayerWrapper";

import { AnwbPlayer } from "@topgun/shared-front-end/src/components/AnwbPlayer/AnwbPlayer";
import ContentIdentifier from "@topgun/shared-front-end/src/components/AnwbPlayer/ContentIdentifier";
import CenteredSpinner from "@topgun/shared-front-end/src/components/CenteredSpinner";
import { MarkdownFormattedContext } from "@topgun/shared-front-end/src/providers/MarkdownFormattedProvider";
import { ServerSideEventDispatcherProvider } from "@topgun/shared-front-end/src/providers/ServerSideEventDispatcherProvider";

import {
  LearningObjectContext,
  useEndUserServerSideEventDispatcher,
} from "../../event/dispatcher/ServerSideEventDispatcherContext";

interface Props {
  pageIndex: number;
  onPreviousClick: () => void;
  onNextClick: () => void;
  learningObjectContext: LearningObjectContext;
  contentReference?: string;
}

const Player: FunctionComponent<Props> = ({
  pageIndex,
  onPreviousClick,
  onNextClick,
  learningObjectContext,
  contentReference,
}) => {
  const { data: learningObject } = usePlayLearningObject(learningObjectContext.learningObjectId);
  const dispatcher = useEndUserServerSideEventDispatcher(learningObjectContext);

  if (!learningObject || !dispatcher) {
    return (
      <SpinnerWrapper>
        <CenteredSpinner />
      </SpinnerWrapper>
    );
  }

  const hasPreviousPage = pageIndex !== 0;

  return (
    <>
      {contentReference && <ContentIdentifier id={contentReference} />}
      <PlayerContainer data-testid="learning-object-player">
        <PlayerWrapperStyledComponent>
          <PlayerWrapper contentType={learningObject.type}>
            <MarkdownFormattedContext.Provider value={learningObject.isMarkdownFormatted}>
              <ServerSideEventDispatcherProvider dispatcher={dispatcher}>
                <AnwbPlayer
                  contentType={learningObject.type}
                  contentTypeOptions={learningObject.content}
                />
              </ServerSideEventDispatcherProvider>
            </MarkdownFormattedContext.Provider>
          </PlayerWrapper>
          <NavigationButtonWrapper>
            {hasPreviousPage && (
              <NavigationButton $variant="previous" icon="arrow-left" onClick={onPreviousClick} />
            )}
            <NavigationButton
              data-testid="next-page-button"
              icon="arrow-right"
              $variant="next"
              onClick={onNextClick}
            />
          </NavigationButtonWrapper>
        </PlayerWrapperStyledComponent>
      </PlayerContainer>
      {createPortal(
        <MobileNavigationButtonWrapper>
          {hasPreviousPage && (
            <MobileBackButton icon="arrow-left" $variant="previous" onClick={onPreviousClick} />
          )}
          <MobileNextButton icon="arrow-right" $variant="next" onClick={onNextClick} />
        </MobileNavigationButtonWrapper>,
        document.body,
      )}
    </>
  );
};

export default Player;
